import React from "react";
import { useQuery } from "@apollo/client";
import { FaBook, FaBuilding, FaUserAlt } from "react-icons/fa";
import { EMPLOYEE_NOT_READY_FOR_REVIEW_DRAFTS } from "queries/newDraft";
import { Client, Employee, NewProject, NewReference } from "generated/graphql";
import { Avatar } from "../avatar";
import { ActionLink } from "components/button";
import { LoadingSpinner } from "components/loadingSpinner";
import { useMultiLang } from "helpers/multiLang";
import { getShortProjectId } from "pages/newProject/header";
import { useTranslation } from "react-i18next";

// HELPER components

const ProjectOpenDraft = ({ draft }: { draft: NewProject }) => {
  const approved = draft.approved as NewProject;
  const m = useMultiLang();
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div>
          <div className="inline-block px-2 mr-3 mb-2 ml-0 text-xs text-blue-500 whitespace-nowrap bg-blue-500 bg-opacity-10 rounded border-none sm:text-sm">
            {getShortProjectId(approved.abacusProjectId ?? "")}
          </div>
          <div>{m(approved.name)}</div>
        </div>
        <div>
          <ActionLink
            link={`/project/${approved.id}`}
            label={"Show Project"}
            Icon={FaBook}
          />
        </div>
      </div>
    </div>
  );
};

const ReferenceOpenDrafts = ({ draft }: { draft: NewReference }) => {
  const approved = draft.approved as NewReference;
  // Reference can be a draft on an existing reference, or a new reference
  const reference = approved ?? draft;
  const m = useMultiLang();
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div>
          {reference.projectId ? (
            <>
              <div className="inline-block px-2 mr-3 mb-2 ml-0 text-xs text-blue-500 whitespace-nowrap bg-blue-500 bg-opacity-10 rounded border-none sm:text-sm">
                {getShortProjectId(reference.project?.abacusProjectId ?? "")}
              </div>
              <div>{m(reference.project?.name)}</div>
            </>
          ) : (
            <div>{reference.employee?.fullName}</div>
          )}
        </div>
        <div>
          <ActionLink
            link={`/employee/${reference.employeeId}/references#${draft.id}`}
            label={"Show Reference"}
            Icon={FaBook}
          />
        </div>
      </div>
    </div>
  );
};

const EmployeeOpenDraft = ({ draft }: { draft: Employee }) => {
  const approved = draft.approved as Employee;
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center gap-x-4">
          <Avatar
            size="small"
            url={approved.colorProfilePicture ?? approved.profilePicture}
          />
          <div className="text-xl">{approved.fullName}</div>
        </div>
        <ActionLink
          link={`/employee/${approved.id}`}
          label={"Show Profile"}
          Icon={FaUserAlt}
        />
      </div>
    </div>
  );
};

const ClientOpenDrafts = ({ draft }: { draft: Client }) => {
  const approved = draft.approved as Client;
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div className="text-xl">{approved.name}</div>
        <ActionLink
          link={`/employee/${approved.id}`}
          label={"Show Client"}
          Icon={FaBuilding}
        />
      </div>
    </div>
  );
};

// MAIN COMPONENT

type EmployeeOpenDraftsProps = {
  employeeId: string;
};

export const EmployeeOpenDrafts: React.FC<EmployeeOpenDraftsProps> = ({
  employeeId,
}) => {
  const { data, loading } = useQuery(EMPLOYEE_NOT_READY_FOR_REVIEW_DRAFTS, {
    variables: { id: +employeeId },
  });

  const { t } = useTranslation("employee");

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="my-8 flex flex-col space-y-8">
      <p className="text-gray-600 italic">{t("openDraftsDesc")}</p>
      {data?.projectDrafts?.length == 0 &&
      data?.employeeDrafts?.length == 0 &&
      data?.clientDrafts?.length == 0 &&
      data?.referenceDrafts?.length == 0 ? (
        <div className="text-lg text-gray-500">{t("noOpenDrafts")}</div>
      ) : null}
      {data?.projectDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("projectDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.projectDrafts?.map((draft: NewProject) => {
              return <ProjectOpenDraft key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.employeeDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("employeeDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.employeeDrafts?.map((draft: Employee) => {
              return <EmployeeOpenDraft key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.clientDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("clientDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.clientDrafts?.map((draft: Client) => {
              return <ClientOpenDrafts key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.referenceDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("referenceDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.referenceDrafts?.map((draft: NewReference) => {
              return <ReferenceOpenDrafts key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
