import {
  useMutation,
  useQuery,
  DocumentNode,
  ApolloClient,
  useApolloClient,
} from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  FaSave,
  FaRegTrashAlt,
  FaTrashAlt,
  FaCheck,
  FaTimes,
  FaExchangeAlt,
  FaSpinner,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { getErrorMessage } from "helpers/ui/errorMessage";

import { IconButton } from "components/button";

import { Modal } from "../modal";
import { LanguageSelector } from "../languageSelector";
import {
  languages,
  getCurrentSupportedLanguage,
} from "../../helpers/languages";
import { Form, FormDefinition, extractMultis, FieldDefinition } from "../form";
import {
  Client,
  Employee,
  FileAttachment,
  NewProject,
  NewReference,
  ProjectTeamMember,
  WikiPage,
  Onepager,
} from "generated/graphql";
import { FaExternalLinkAlt } from "react-icons/fa";
import debounce from "debounce";
import { useMultiLang } from "helpers/multiLang";
import { Popup } from "components/popup";

export type VersionedEntity =
  | NewProject
  | Client
  | Employee
  | NewReference
  | ProjectTeamMember
  | WikiPage
  | FileAttachment
  | Onepager;

/**
 * The EditVersionedProps define all properties needed to build the generic form for a versioned entity (e.g. a client, employee, ...)
 */
export type EditVersionedProps<T extends VersionedEntity> = {
  /**
   * ID of the entity to edit (e.g. an employee's id or a client's id)
   * If the id equals "new", a new entity will be created.
   */
  id: string;
  /**
   * The mutation to save the versioned entity
   */
  editMutation: DocumentNode;
  /**
   * The mutation to create a new entity
   */
  createMutation?: DocumentNode;
  /**
   * The mutation to delete an entity
   */
  deleteMutation?: DocumentNode;
  approveDraftMutation?: DocumentNode;
  rejectDraftMutation?: DocumentNode;
  /**
   * The property in the mutation result data which contains the actual entity.
   */
  createDataAccessor?: string;
  editDataAccessor: string;
  /**
   * The query to use to query the versioned entity
   */
  query: DocumentNode;
  /**
   * The property in the query result data which contains the actual entity.
   * E.g. for the GetClient query, this would be "client" because the data returned from GraphQL nests the client in that property.
   */
  queryDataAccessor: string;
  /**
   * The form definition to use for building the form
   */
  formDefinition: FormDefinition<T>;
  /**
   * An array of query names to refetch after the entity was saved
   */
  refetchQueries: string[];
};

export interface ISpecializedEditFormProps {
  id: string;
  onSaved: () => any;
  onCreated?: (id: string) => any;
  isOnAdminPage: boolean;
}

// To ensure only the allowed properties are sent to the
// GraphQL endpoint to update the versioned entities,
// we strip all values not defined in the form definition.
export const stripUnknownValuesBeforeSave = <T extends VersionedEntity>({
  values,
  formDefinition,
}: {
  values: Partial<T> | undefined;
  formDefinition: FormDefinition<T>;
}): { [key: string]: any } => {
  const sendingValues = [
    ...Object.keys(formDefinition.fields),
    "connectedFields",
    "isReadyForReview",
  ].reduce((acc, key) => {
    const definition: FieldDefinition<any> | undefined =
      formDefinition.fields[key];
    if (definition?.skipFieldOnSave) {
      return acc;
    }
    if (definition?.component?.onBeforeSave) {
      if (definition?.controlledFields) {
        const newValues = definition.component?.onBeforeSave(values) ?? {};
        return { ...acc, ...newValues };
      } else {
        return {
          ...acc,
          [key]: definition.component?.onBeforeSave(values?.[key]),
        };
      }
    } else if (definition) {
      return { ...acc, [key]: values?.[key] };
    } else if (key === "connectedFields" && values && values[key]) {
      const connectedFields = values[key]?.map((v) => {
        const { field, isConnected } = v;
        return { field, isConnected };
      });
      return { ...acc, [key]: connectedFields };
    } else if (key === "isReadyForReview") {
      return { ...acc, isReadyForReview: values?.isReadyForReview ?? false };
    } else {
      return acc;
    }
  }, {});

  return extractMultis<T>({ values: sendingValues as T, formDefinition });
};

export const toMultiLangField = (
  FieldComponent: React.ComponentType<any>,
): React.FC<any> => {
  return (props) => {
    const { value, onChange, language } = props;
    return (
      <FieldComponent
        {...props}
        multiLangValue={value}
        value={value?.[language]}
        onChange={(newValue) => {
          return onChange({ ...value, [language]: newValue });
        }}
      />
    );
  };
};

const extractSaveActions = <T extends VersionedEntity>(
  apolloClient: ApolloClient<any>,
  values: Partial<T> | undefined,
  formDefinition: FormDefinition<T>,
) => {
  return Object.keys(formDefinition.fields)
    .filter((key) => {
      const definition: FieldDefinition<any> = formDefinition.fields[key];
      return definition.onSaveAction ?? false;
    })
    .map((key) => {
      const definition: FieldDefinition<any> = formDefinition.fields[key];
      return definition?.onSaveAction?.(
        apolloClient,
        values ?? {},
        values?.[key],
      );
    });
};

const useEditVersioned = <T extends VersionedEntity>(
  {
    id,
    editMutation,
    createMutation,
    deleteMutation,
    approveDraftMutation,
    rejectDraftMutation,
    createDataAccessor,
    editDataAccessor,
    query,
    queryDataAccessor,
    formDefinition,
    refetchQueries,
  }: EditVersionedProps<T>,
  newEntity?: Partial<T>,
): {
  loading: boolean;
  entity: Partial<T> | undefined;
  approvedEntity: Partial<T> | undefined;
  setEntity: any;
  save: (values: Partial<T>) => Promise<string | null>;
  deleteEntity: () => Promise<string | null>;
  canDeleteEntity: boolean;
  approveDraft: (id: string) => Promise<null>;
  rejectDraft: (id: string) => Promise<null>;
  draftId: string | null;
  debouncedSave: (values: Partial<T>) => void;
  error: null | string;
  saving: boolean;
} => {
  const isNewEntity = id === "new";
  if (isNewEntity && (!createDataAccessor || !createMutation)) {
    throw new Error(
      "To create new entities, you must provide both 'createDataAcessor' and 'createMutation'.",
    );
  }
  const [editMut] = useMutation<T>(editMutation);
  const [approveMut] = approveDraftMutation
    ? useMutation(approveDraftMutation)
    : [];
  const [rejectMut] = rejectDraftMutation
    ? useMutation(rejectDraftMutation)
    : [];

  const [delMut] = deleteMutation ? useMutation<T>(deleteMutation) : [];
  const [createMut] = createMutation ? useMutation<T>(createMutation) : [];
  const [entity, setEntity] = useState<Partial<T> | undefined>(
    isNewEntity ? ((newEntity ?? {}) as T) : undefined,
  );
  const [draftId, setDraftId] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { data, loading } = useQuery(query, {
    variables: { id },
    skip: isNewEntity,
    fetchPolicy: "no-cache",
  });

  const debouncedSave = useCallback(
    debounce(async (values) => {
      const result = await savePromise(values).catch((_err) => null);
      // @ts-ignore
      setDraftId(result?.data?.[editDataAccessor]?.draft?.id ?? null);
      setSaving(false);
      return result;
    }, 500),
    [id],
  );

  const apolloClient = useApolloClient();

  const saveActions = (values) =>
    extractSaveActions(apolloClient, values || entity, formDefinition);

  const savePromise = (values) =>
    Promise.all(saveActions(values))
      .then((_) =>
        editMut({
          variables: {
            id,
            fields: stripUnknownValuesBeforeSave({
              values: values || entity,
              formDefinition,
            }),
          },
          refetchQueries,
        }),
      )
      .catch((err) => {
        setError(JSON.stringify(err));
        setSaving(false);
        throw err;
      });

  const create = async (values: Partial<T>): Promise<null | string> => {
    const result = createMut
      ? await createMut({
          variables: {
            fields: stripUnknownValuesBeforeSave({
              values: values || entity,
              formDefinition,
            }),
          },
          refetchQueries,
        }).catch((err) => {
          setError(JSON.stringify(err));
          setSaving(false);
          throw err;
        })
      : null;
    setSaving(false);
    if (!result) {
      return null;
    }
    const newId = result.data![createDataAccessor!].id;
    return newId;
  };

  const save = (values: Partial<T>): Promise<string | null> => {
    setSaving(true);
    if (isNewEntity) {
      return create(values);
    } else {
      return savePromise(values).then((_result) => null);
    }
  };

  const deleteEntity = async (): Promise<null> => {
    if (delMut) {
      await delMut({
        variables: {
          id,
        },
        refetchQueries,
      }).catch((err) => {
        setError(JSON.stringify(err));
        setSaving(false);
        throw err;
      });
      if (entity) {
        // this way the UI is immediately updated on delete
        setEntity({ ...entity, deletedAt: `${new Date()}` });
      }
    }

    setSaving(false);
    return null;
  };

  const approveDraft = async (dId: string): Promise<null> => {
    if (!approveMut) {
      return null;
    }
    await approveMut({
      variables: {
        id: dId,
      },
    }).catch((err) => {
      setError(JSON.stringify(err));
      setSaving(false);
      throw err;
    });

    setDraftId(null);
    setSaving(false);
    return null;
  };

  const rejectDraft = async (dId: string): Promise<null> => {
    if (!rejectMut) {
      return null;
    }
    await rejectMut({
      variables: {
        id: dId,
      },
    }).catch((err) => {
      setError(JSON.stringify(err));
      setSaving(false);
      throw err;
    });

    setDraftId(null);
    setSaving(false);
    // reset to starting state
    setEntity(data[queryDataAccessor]?.draft || data[queryDataAccessor]);
    return null;
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setEntity(data[queryDataAccessor]?.draft || data[queryDataAccessor]);
    setDraftId(data[queryDataAccessor]?.draft?.id ?? null);
  }, [data]);

  return {
    loading: loading || !entity,
    entity,
    approvedEntity: data?.[queryDataAccessor],
    setEntity,
    save,
    deleteEntity,
    canDeleteEntity: !!deleteMutation,
    approveDraft,
    rejectDraft,
    draftId,
    debouncedSave,
    error,
    saving,
  };
};

export const SavingIndicator = ({
  saving,
}: {
  saving: boolean;
}): JSX.Element => {
  return (
    <IconButton
      disabled={true}
      type="secondary"
      Icon={saving ? AiOutlineLoading3Quarters : AiFillCheckCircle}
    >
      {saving ? "Speichern..." : "Gespeichert"}
    </IconButton>
  );
};

export const EditForm = <T extends VersionedEntity>({
  editVersioned,
  onCreated,
  isOnAdminPage,
}: {
  isOnAdminPage: boolean;
  onSaved: () => any;
  onCreated?: (id: string) => any;
  editVersioned: EditVersionedProps<T>;
}): JSX.Element => {
  const { t } = useTranslation(["common", "drafts"]);
  const m = useMultiLang();
  const isNewEntity = editVersioned.id === "new";
  const {
    entity,
    approvedEntity,
    setEntity,
    save,
    approveDraft,
    deleteEntity,
    canDeleteEntity,
    rejectDraft,
    draftId,
    debouncedSave,
    saving,
    error,
  } = useEditVersioned(editVersioned);

  const [language, setLanguage] = useState(languages[0]);
  const title = editVersioned.formDefinition.titleString(entity, t, m);
  const link = approvedEntity
    ? editVersioned.formDefinition.link(approvedEntity)
    : null;

  return !entity ? (
    <div>Loading...</div>
  ) : (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-4 align-baseline">
        <h2 className="text-2xl">
          <span className="text-red-500">“{title}”</span> bearbeiten
        </h2>
        <div className="flex pl-2 space-x-2">
          {link ? (
            <a
              className="flex items-center px-2 py-1 text-sm text-blue-500 whitespace-pre border rounded border-grey-300 hover:bg-opacity-5 hover:border-opacity-40 focus:outline-none hover:bg-blue-500 hover:border-blue-500"
              target="_blank"
              href={link}
              rel="noreferrer"
            >
              <FaExternalLinkAlt aria-hidden="true" />
              <span className="ml-2">Zum Eintrag</span>
            </a>
          ) : null}
          <LanguageSelector
            currentLanguage={language}
            setCurrentLanguage={setLanguage}
          />
        </div>
      </div>
      <div className="relative px-4 overflow-y-auto shrink">
        {entity.deletedAt ? <DeletedOverlay /> : null}
        <Form<T>
          formDefinition={editVersioned.formDefinition}
          values={entity}
          setValues={(values) => {
            setEntity(values);
            if (!isNewEntity) {
              debouncedSave(values);
            }
          }}
          currentLanguage={language}
          newEntity={isNewEntity}
          isOnAdminPage={isOnAdminPage}
        />
      </div>
      <div className="flex items-center justify-end p-4 space-x-2">
        {error ? <div className="bg-red-300">{error}</div> : null}
        {isOnAdminPage &&
        canDeleteEntity &&
        !entity.deletedAt &&
        !isNewEntity ? (
          <IconButton
            type="warning"
            Icon={FaRegTrashAlt}
            onClick={async () => {
              return await deleteEntity();
            }}
          >
            Löschen
          </IconButton>
        ) : null}
        {isOnAdminPage &&
        canDeleteEntity &&
        !entity.deletedAt &&
        isNewEntity ? (
          <IconButton
            type="warning"
            Icon={FaRegTrashAlt}
            onClick={() => {
              setEntity({});
            }}
          >
            {t("common:reset")}
          </IconButton>
        ) : null}
        {isOnAdminPage && draftId ? (
          <>
            <IconButton
              Icon={FaTimes}
              onClick={() => {
                rejectDraft(draftId);
              }}
            >
              {t("drafts:reject")}
            </IconButton>
            <IconButton
              Icon={FaCheck}
              onClick={() => {
                approveDraft(draftId);
              }}
            >
              {t("drafts:approve")}
            </IconButton>
          </>
        ) : null}
        {isNewEntity ? (
          <IconButton
            Icon={FaSave}
            onClick={async () =>
              onCreated &&
              onCreated((await save(entity).catch((_) => "")) ?? "")
            }
          >
            Speichern
          </IconButton>
        ) : !entity.deletedAt ? (
          <SavingIndicator saving={saving} />
        ) : null}
      </div>
    </div>
  );
};

const DeletedOverlay = () => {
  const { t } = useTranslation("common");
  return (
    <div className="absolute top-0 left-0 z-10 block w-full h-full bg-white bg-opacity-90">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col items-center px-4 text-sm text-xl text-center text-gray-500">
          <FaTrashAlt size="3em" className="m-2" />
          <span>{t("deleted")}</span>
        </div>
      </div>
    </div>
  );
};

type EditModalProps<T extends VersionedEntity> = {
  open: boolean;
  editVersioned: EditVersionedProps<T>;
  newEntity?: Partial<T>;
  close: () => void;
  onSave?: (id: string | null) => void;
  hideReviewWorkflowButtons?: boolean;
  hardCodeLanguage?: string;
  AdditionalInfo?: ({ entity }: { entity?: Partial<T> }) => JSX.Element | null;
};

const EditModalInner = <T extends VersionedEntity>({
  open,
  editVersioned,
  newEntity,
  close,
  onSave,
  hardCodeLanguage,
  hideReviewWorkflowButtons,
  AdditionalInfo,
}: EditModalProps<T>) => {
  const { t, i18n } = useTranslation(["common", "error"]);

  const { loading, entity, setEntity, save, deleteEntity } = useEditVersioned(
    editVersioned,
    newEntity,
  );
  const [language, setLanguage] = useState(
    getCurrentSupportedLanguage(i18n.language),
  );
  const Title = editVersioned.formDefinition.title;
  const [errorPresent, setErrorPresent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const FormattedErrorMessage = (): JSX.Element => {
    return (
      <div className="block mt-1 mr-4 font-medium text-red-500">
        {`${errorMessage}`}
      </div>
    );
  };

  const showFormattedErrorMessage = (error) => {
    setErrorPresent(true);
    const message = getErrorMessage(error.message, t);
    setErrorMessage(message);
  };

  const saveAndClose = async () => {
    setIsLoading(true);
    save(entity!)
      .then((id) => {
        onSave?.(id);
        close();
      })
      .catch((e) => {
        showFormattedErrorMessage(e);
      })
      .finally(() => setIsLoading(false));
  };

  const saveReleaseAndClose = async () => {
    setIsLoading(true);
    save({ ...entity!, isReadyForReview: true })
      .then((id) => {
        onSave?.(id);
        close();
      })
      .catch((_) => {
        // no op
      })
      .finally(() => setIsLoading(false));
  };

  const deleteAndClose = async () => {
    deleteEntity()
      .then((_) => {
        close();
      })
      .catch((_) => {
        // no op
      });
  };

  return (
    <Modal
      open={open}
      close={close}
      title={
        <div className="flex flex-col items-start justify-between px-6 py-8 sm:flex-row sm:space-x-4">
          <h2 className="mb-2 text-2xl font-medium text-red-500 sm:mb-none ">
            <Title entity={entity} t={t} close={close} />
          </h2>
          {hardCodeLanguage ? null : (
            <LanguageSelector
              currentLanguage={language}
              setCurrentLanguage={setLanguage}
            />
          )}
        </div>
      }
      actions={
        <div className="flex flex-row-reverse justify-between w-full">
          <div className="flex items-center px-6 space-x-2">
            {errorPresent ? <FormattedErrorMessage /> : null}
            <IconButton type="secondary" Icon={IoClose} onClick={close}>
              {t("common:close")}
            </IconButton>
            {!hideReviewWorkflowButtons ? (
              <Popup content={t("common:changesArePossible")}>
                <IconButton Icon={FaSave} onClick={saveAndClose}>
                  {t("common:save")}
                </IconButton>
              </Popup>
            ) : (
              <IconButton Icon={FaSave} onClick={saveAndClose}>
                {t("common:save")}
              </IconButton>
            )}
            {!hideReviewWorkflowButtons ? (
              <Popup content={t("common:saveAndRelease")}>
                <IconButton Icon={FaExchangeAlt} onClick={saveReleaseAndClose}>
                  {t("common:release")}
                </IconButton>
              </Popup>
            ) : null}
          </div>
          {editVersioned.deleteMutation && entity?.id && entity.id !== "new" ? (
            <div className="flex items-center px-6 space-x-2">
              <IconButton
                type="warning"
                Icon={FaRegTrashAlt}
                onClick={deleteAndClose}
              >
                {t("common:delete")}
              </IconButton>
            </div>
          ) : null}
        </div>
      }
    >
      <div className="px-6">
        <Form<T>
          formDefinition={editVersioned.formDefinition}
          values={entity ?? {}}
          setValues={setEntity}
          currentLanguage={hardCodeLanguage ?? language}
          newEntity={!!newEntity}
          isOnAdminPage={false}
          closeModal={close}
        />
        {AdditionalInfo ? (
          <div className="w-full border-t border-gray-300 mt-4 pt-4 pb-8">
            <AdditionalInfo entity={entity} />
          </div>
        ) : null}
        {loading || isLoading ? (
          <div className="absolute top-0 left-0 z-30 w-full h-full bg-white bg-opacity-50">
            <div className="flex items-center justify-center h-full">
              <div className="p-3 text-xl">
                <FaSpinner className="animate-spin" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export const EditModal = <T extends VersionedEntity>(
  props: EditModalProps<T>,
): JSX.Element | null => {
  // We need to clear the state of the modal when it is closed
  return props.open ? <EditModalInner {...props} /> : null;
};
